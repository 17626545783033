@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,700;1,100;1,500&display=swap');

.App {
  /*background-color: #5a8447;*/
  min-height: 100%;
  color: white;
}

.missao-icon {
  display: flex;
  padding: 50px 0 20px 0;
  font-size: 1.3rem;
  font-family: 'Poppins';
  font-weight: 300;
  width: 100%;
}

.missao ul {
  padding: 0 !important;
}

.missao-icon div {
  min-width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.missao-icon h4 {
  padding: 0 0 20px 0;
  font-weight: 500;
}

.missao-icon img {
  max-width: 50px;
  filter: invert(1);
}

.missao {
  display: flex;
}

.missao div {
  min-width: 25%;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.missao div:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.missao-icon div:nth-child(3) {
  min-width: 20%;
}

.missao div:nth-child(3) {
  min-width: 20%;
  border-right: 1px solid white;
}

.missao div:nth-child(4),
.missao-icon div:nth-child(4) {
  min-width: 30%;
}

.missao p {
  text-align: center;
  padding: 0 20px;
}

.missao ul {
  list-style: '★' outside none;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*
    DropDown
    ##################
*/

.dropdown-item {
  padding: 7px 20px !important;
  color: white !important;
}

.dropdown-item:hover {
  padding-top: 0;
  border-left: 15px solid transparent;
}

.dropdown-menu .dropdown-item:hover {
  padding: 7px 20px !important;

  color: #5a8447 !important;
}

.dropdown-menu.show {
  background-color: #182e0d;
  margin-top: 0;
  color: #5a8447;
  border-radius: 0 0 5px 5px;
}

.dropdown-menu.show a:hover {
  transition-duration: 0.5s;
}

.dropdown-menu {
  font-size: inherit;
  margin-top: 7px !important;
  position: absolute;
  top: 100%;
  left: 0;
  border-top: none;
  display: inline-block;
  background-color: #3e612d;
  border-radius: 0 0 5px 5px;
}

.App-link {
  color: #61dafb;
}

.navbar {
  width: 100vw;
  background-color: white;
  color: #3e612d;
  z-index: 3;
  top: 0;
  flex-direction: column !important;
  font-size: 0.8rem !important;
  padding: 0 !important;
}

.logo-top img {
  width: 220px;
}

.nav-item {
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  /* font-family: "Roboto"; */
}

.nav-item a {
  color: #ffffff;
}

.nav-item:hover {
  background-color: #1a2e10;
  border-radius: 10px 10px 0 0;
}

.nav-item:hover a {
  color: white;
}

.nav-link {
  display: block;
  padding: 0.5rem 0;
}

.margin-top {
  background-color: #f8f8f8;
  color: #5a8447;
  padding-bottom: 10px;
}

.margin-top ol {
  font-size: 1rem;
}

ol {
  text-align: left;
}

.carousel-wrapper {
  width: 100%;

  z-index: -1;
}

.carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.top-topo {
  display: flex;
  justify-content: space-around;
  background-color: #5a844721;
  width: 100%;
  color: #5a8447;
  padding: 10px 0;
}

#navbarSupportedContent {
  background-color: #304925;
}

.holder {
  width: 1200px;
  margin: 0 auto;
}

.flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.nav-desktop {
  position: sticky !important;
  top: 0 !important;
}

.servicosContainer {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  text-align: justify;
  padding: 0 20px;
}

.servicosContainer .servico-div {
  margin-top: 40px;
  width: calc(25% - 20px);
  margin-right: 20px;
  color: #545454;
  position: relative;
  border-top: 4px solid #3e612d;
  padding: 0 0 0 10px;
}

.servicosContainer h2 {
  font-weight: 500 !important;
}

.servicosContainer .servico-div h3 {
  margin-bottom: 0 !important;
}

.servicosContainer .servico-div span {
  margin-top: 10px !important;
}

.servicosContainer .servico-div .botao {
  border-color: #5a8447;
  color: #5a8447;
}

.servicosContainer .servico-div .botao:hover {
  background-color: #3e612d;
  color: white;
}

.servicosContainer .botao {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -moz-transition: ease-in-out 0.3s all;
  -o-transition: ease-in-out 0.3s all;
  -webkit-transition: ease-in-out 0.3s all;
  transition: ease-in-out 0.3s all;
  padding: 6px 11px 7px;
  border: 1px solid #ff7900;
  color: #ff7900;
  font-size: 0.8em;
  font-weight: 900;
  display: inline-block;
  margin-top: 30px;
}

.servicosContainer .servico-div span {
  font-size: 0.85em;
  width: 100%;
  display: block;
}

.conteudo-pagina {
  background-color: #efefef;
  color: #386127;
  padding: 10px 0px 50px;

  font-size: 16px;
  font-weight: 300;
  overflow-x: hidden;
}

.pdf-container {
  width: 960px;

  margin: 0 auto;
}

.servicosContainer:first-of-type h2 {
  margin-top: 15px;
}

.servicosContainer h2 {
  font-weight: 300;
  font-size: 1.3em;
  width: 100%;
  margin-top: 85px;
}

.servicosContainer a h3 {
  font-weight: 900;
  font-size: 1.1em;
  margin: 0 0 20px;
  width: 100%;
}

.conteudo-pagina,
.servicosContainer a,
.historico p,
.contato form #formRight input[type="submit"],
.noticia_single .conteudoPost,
.noticia_single .conteudoPost a,
.template-servico .form-servico form input[type="submit"],
.duvidas .holder .duvida-item .consulta_form form input[type="submit"],
.menu.buscaClicada a,
#loadMoreEscritTramit,
#loadMoreEscritPart {
  color: #386127;
}

.tabela-emolumentos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabela-emolumentos i {
  margin-right: 10px;
}

.tabela-emolumentos img {
  width: 70%;
}

.utilidades {
  margin-top: -300px;
}

.utilidades h3 {
  text-align: left;
}

.content-internas {
  display: flex;
  flex-direction: column;
}

.content-internas p {
  text-align: justify !important;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.page-header-ri-background,
.page-header-ri-background-legislacao,
.page-header-ri-background-modeloReq,
.page-header-ri-background-formulario,
.page-header-ri-background-question,
.page-header-ri-background-andamento,
.page-header-ri-background-certidoes,
.page-header-ri-background-fale-conosco,
.page-header-ri-background-pesquisa-satisfacao {
  height: 300px;
  background-size: cover;

  position: relative;
}

.page-header-ri-background {
  background-image: url("./imgs/top-blank.jpg");
  background-position: center center;
}

.page-header-ri-background-legislacao {
  background-position: center -120px;
}

.page-header-ri-background-modeloReq {
  background-position: -150px -320px;
  background-size: 120%;
  opacity: 0.4;
}

.page-header-ri-background-formulario {
  background-position: center -540px;
  opacity: 0.6;
  background-color: #5a8447;
}

.page-header-ri-background-question {
  background-position: center -220px;
  opacity: 0.2;
}

.page-header-ri-background-certidoes {
  background-position: center -220px;
  opacity: 0.2;
}

.page-header-ri-background-fale-conosco {
  background-position: center -220px;
  opacity: 0.2;
}

.page-header-ri-background-pesquisa-satisfacao {
  background-position: right -100px;
  background-size: 60%;
  background-repeat: no-repeat;
  background-color: #000000;
  opacity: 0.9;
}

.pesquisa h3 {
  color: white !important;
}

.click-tabela img {
  opacity: 0.4;
}

.click-tabela:hover img {
  opacity: 1;
}

.click-tabela:hover p {
  display: block;
  opacity: 1;
}

.click-tabela {
  display: flex;
  z-index: 1;

  justify-content: center;
}

.transition {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

img {
  width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
}

.form-pesquisa {
  width: 720px !important;
  margin-top: -400px;
  margin-bottom: 100px;
  z-index: 30 !important;
  position: relative;
  border-radius: 10px;
  box-shadow: 2px 5px 10px #52525244;
  font-size: 1rem;
  padding: 30px 40px;

  background-color: white;
}

.checks-inline {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.checks-inline .radio-inline {
  display: flex;
  flex-direction: column;
}

.form-check-input {
  margin-right: 10px;
  margin-top: 0 !important;
}

.radio-inline {
  display: flex;
}

.radio-inline .form-check-input {
  margin: 0 !important;
}

.page-header-ri-andamento {
  height: 330px !important;
  border-top: 5px solid #5a8447;
}

.page-header-ri {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
  opacity: 1 !important;
  top: -350px;
  font-family: "Open sans";
  position: relative;
}

.page-header-ri h3 {
  color: #bedbb1;
  font-size: 2rem;
}

.page-header-ri h3::after {
  content: "";
  display: block;
  margin-top: 5px;
  width: 40px;
  border-top: 2px solid #5a8447;
}

.page-header-ri span {
  margin-top: -30px;
}

.no-header-page {
  width: 100%;
  border-top: 5px solid #5a8447;
}

.quem-somos blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 0.9rem;
  border-left: 5px solid #eeeeee;
  text-align: justify;
}

.consulta-protocolo {
  margin-top: 50px;
}

.consulta-protocolo div {
  text-decoration: none;
  color: inherit;
  border-left: 5px solid #5a8447;
  background-color: #eeeded;
  padding: 10px 25px;
}

.consulta-protocolo div:hover {
  text-decoration: underline;
  opacity: 0.8 !important;
}

.consulta-no-selected {
  opacity: 0.2;
}

.subtitle {
  font-size: 0.8rem;
}

.text-justify {
  text-align: justify !important;
}

.form-consulta {
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: flex-start;
  margin: 50px auto;
  font-size: 1rem;
  width: 400px;
}

.clickable:hover {
  cursor: pointer;
}

.quem-somos {
  width: 100%;
  padding-bottom: 150px;
  margin-top: -250px !important;
}

.quem-somos p {
  text-align: left;
  font-size: 0.9rem;
}

.quem-somos h4 {
  font-weight: bold;
}

.quem-somos a {
  font-size: 1rem !important;
}

.panel-respostas {
  border: 1px solid #5a8447;
  padding: 5px;
  text-align: left;
  margin: 5px 0;
  padding: 0;
  background-color: #5a8447;
  border-radius: 5px;
}

.panel-respostas button .panel-title {
  font-weight: normal;
  font-size: 1rem;
  text-align: left;
  margin-left: 15px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.panel-title::before {
  content: "";
}

.panel-title {
  color: white;
}

.panel-body {
  border-radius: 5px;
}

.panel-body p {
  font-size: 1rem;
}

.panel-collapse {
  padding: 10px;
  background-color: white;
  border-radius: 0 0 5px 5px;
}

.panel-respostas button {
  border: 0 !important;
  padding: 8px 0 !important;
  margin: 0 !important;
  background-color: #5a8447;
  width: 100%;
  display: flex;
}

.destaques {
  margin-top: 30px;
  background-color: #f8f8f8 !important;
  color: #5a8447 !important;
}

.destaques h5::after {
  content: "";
  display: block;
  margin-top: 2px;
  width: 40px;
  border-top: 2px solid #4094dc;
}

.row>* {
  padding-left: calc(var(--bs-gutter-x) * 0.2);
  padding-right: calc(var(--bs-gutter-x) * 0.2);
  padding-bottom: calc(var(--bs-gutter-x) * 0.5);
  align-content: stretch;
  display: flex;
}

.noticias {
  margin: 0 !important;
}

.noticias>div>div {
  border: 1px solid #415161;
  border-radius: 5px;
  color: #5a8447;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.noticias>div>div img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.noticias div .new-title,
.noticias div .new-author,
.noticias div .new-content {
  padding: 5px 15px;
}

.noticias div .new-title {
  font-size: 0.9rem;
  font-weight: bold;
}

.noticias div .new-author {
  font-size: 0.6rem;
  width: 100%;
  display: flex;
}

.noticias div .new-content {
  font-size: 0.8rem;
  text-align: justify;
  padding-bottom: 10px;
}

.noticias button {
  background: linear-gradient(#61dafb, #5a8447);
  width: fit-content;
  color: white;
  border: 1px solid #fff;
  transition: transform 0.2s;
  /*box-shadow: 2px 2px 5px #1d6072;*/
}

.noticias button:hover {
  transform: scale(1.05);
  border: 1px solid white;
  color: white;
}

.modelos-req {
  text-align: left;

  width: 100%;
  margin: 2px 0;
  padding: 5px;
}

.modelos-req ul {
  list-style-type: none;
  margin-left: -20px;
}

.modelos-req blockquote {
  border-left: 5px solid #4093dc27;
  margin-left: 45px;
}

.modelos-req blockquote p {
  margin-left: 10px;
}

.modelos-req i {
  font-size: 1.5rem;
  margin: 0 5px;
}

.modelos-req a {
  font-size: 1rem;
  text-decoration: none;
  color: inherit;
}

.modelos-req a:hover {
  text-decoration: underline;
}

.modal-photos {
  padding: 25px;
  position: absolute !important;
}

.modal-photos button {
  z-index: 1000;
  position: absolute;
  top: 15px;
  right: 15px;
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.gallery-small img {
  height: fit-content !important;
}

.ReactModal__Overlay {
  z-index: 999;
  top: 50%;
  left: 50%;
  margin-left: -35%;
  margin-top: -35%;
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-photos div {
  display: flex;
  flex-direction: column;
}

.photo-gallery {
  display: flex;
  flex-basis: auto;
  flex-grow: auto;
  flex-wrap: wrap;
}

.photo-gallery div {
  width: 200px;
  margin: 5px;
}

footer {
  background-color: rgba(255, 255, 255, 0.863);
  width: 100%;
  margin: 0;
  padding: 10px 0;
  font-size: 0.8rem;
  color: black;
}

footer a img {
  width: 10%;
}

.footer-ri {
  padding: 20px 0;
  margin: 0;
}

.footer-ri ul {
  list-style-type: none;
  font-size: 1rem;
}

.footer-ri ul p {
  font-size: 1.2rem;
}

.footer-ri ul p::after {
  content: "";
  display: block;
  margin-top: 2px;
  width: 40px;
  border-top: 2px solid #5a8447;
}

.footer-ri ul li {
  font-weight: 200;
  font-size: 0.9rem;
  text-align: left;
  color: #182e0d
}

.footer-ri ul li:hover a {
  text-decoration: underline;

}

.servicos-top {
  display: flex;
  width: 100%;

  align-items: center;

  font-size: 2rem;
}

.servicos-top i {
  margin: 0 5px;
}

.servicos-online {
  background-color: #f8f8f8;
  margin: 0;
}

.servicos {
  display: flex;

  align-items: center;
  flex-direction: column;
  margin: 20px 0 !important;
  font-size: 1.2rem;
  z-index: 3;
  padding: 0 !important;
  justify-content: center;
  /* position: relative;*/
}

.servicos-div {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
}

.servicos-div div {
  justify-content: space-around;
}

.servicos-div a {
  display: flex;
  width: 20%;
  border-radius: 10px;
  flex-direction: column;
  padding: 0 !important;
  background-color: #f8f8f8;
  margin: 15px 0;
  color: #5a8447;
  align-items: center;
  justify-content: center;
  height: 190px;
  text-decoration: none;
  cursor: pointer;
  z-index: 2;
}

.servicos-div a:hover {
  transform: scale(1.1);
  cursor: pointer;
  z-index: 3;
}

.servicos-div i {
  font-size: 2.5rem;
}

.servicos-inside img {
  width: auto !important;
}

.fs-6 {
  font-size: 0.8rem !important;
}

.fs-6-9 {
  font-size: 0.9rem !important;
}

.fs-6-5 {
  font-size: 1rem !important;
}

.tipos-servicos {
  text-align: left;
}

@media screen and (min-width:769px) {

  .missao-mobile,
  .missao-icon-mobile {
    display: none;
  }

}

@media screen and (max-width: 768px) {
  html {
    font-size: 1.3rem !important;
  }

  .missao-desktop,
  .missao-icon-desktop {
    display: none;
  }

  .missao-mobile {
    display: flex;
    flex-direction: column;
    padding: 70px 0 0 0;
  }

  .missao-mobile div:nth-child(2),
  .missao-mobile div:nth-child(3) {
    border: 0;
  }

  .missao-mobile img {
    width: 20%;
    filter: invert(1);
  }

  .nav-mobile {
    margin-left: 15px;
  }

  .top-topo span {
    display: none;
  }

  .nav-mobile a:before {
    content: "- ";
  }

  .nav-link {
    font-weight: bold;
  }

  .dropdown-menu-mobile .dropdown-item {
    text-align: left;
    color: #5a8447 !important;
  }

  .carousel-wrapper {
    height: 390px;
    margin-top: 0;
  }

  .carousel img {
    height: 390px;

    object-fit: cover;
  }

  footer .logo {
    display: none;
  }

  footer {
    display: none;
  }

  footer {
    padding: 10px 0;
  }

  .servicosContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
    padding: 0 10px;
  }

  .servicos {
    align-items: center;
    justify-content: center;
  }

  .servico-div {
    width: 100% !important;
    border-top: 0 !important;
    margin: 5px 0 !important;
    padding: 10px 15px !important;
    border-radius: 5px;
    background-color: white;
  }

  .servicos-div div {
    padding: 5px;
  }

  .servico-div div h3 {
    font-weight: 500;
    margin-block-end: 0 !important;
    margin-block-start: 0 !important;
  }

  .servicosContainer .servico-div span {
    margin: 0 !important;
  }

  .servicosContainer h2 {
    font-weight: 500;
  }

  .servicos-div div a {
    width: 100%;
  }

  .page-header-ri-background {
    background-position: 0 0;
  }
}