.CookieConsent {
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: 490px !important;
  border: 2px solid white;
  background-image: linear-gradient(to bottom, #3e612d, #000) !important;
  border-radius: 10px;
  box-shadow: 1px 1px 5px white;
  font-size: 0.8rem !important;
  margin: 20px;
  padding: 10px 20px;
  align-items: center !important;
}

.CookieConsent div {
  flex: 1 0 0px !important;
  margin: 0 !important;
  justify-content: center !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
}
.CookieConsent p {
  margin: 0 !important;
}
@media screen and (max-width: 768px) {
  .CookieConsent {
    width: 90vw !important;
  }
}
